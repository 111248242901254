import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const BCMGuidesGearSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gear Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_sets.png"
            alt="Best gear sets"
          />
        </div>
        <div className="page-details">
          <h1>Gear Sets</h1>
          <h2>List of available gear sets in Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Gear Sets" />
        <p>
          Gear Sets in Black Clover Mobile ones are split into basic and
          advanced ones. You can recognize the advanced gear sets by a golden
          border surrounding the icon. The advanced gear sets start dropping
          from the 10th stage of each gear dungeon.
        </p>
        <SectionHeader title="Basic gear sets" />
        <Table striped bordered responsive className="table-sets bcm">
          <thead>
            <tr>
              <th>Icon</th>
              <th>Set Name</th>
              <th>Set Effects</th>
              <th>Drops from</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_acc.png"
                  alt="Acc Set"
                />
              </td>
              <td>ACCURACY</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Accuracy +10
                  </li>
                  <li>
                    <span>(4)</span> - Accuracy +10% and CRIT Rate +5%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="teal">Blue Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_atk.png"
                  alt="Atk Set"
                />
              </td>
              <td>ATK</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - ATK +220 and MATK +220
                  </li>
                  <li>
                    <span>(4)</span> - Damage +10%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_crit.png"
                  alt="Crit Set"
                />
              </td>
              <td>CRIT Rate</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Gain Crit Rate +10% when HP is above 70%
                  </li>
                  <li>
                    <span>(4)</span> - Gain Crit Rate +10% buff for 2 turns if
                    last attack wasn't a critical hit
                  </li>
                </ul>
              </td>
              <td>
                <strong className="teal">Blue Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_critdmg.png"
                  alt="Crit Dmg Set"
                />
              </td>
              <td>CRIT DMG</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Gain Crit DMG +10% before using Special
                    Skill
                  </li>
                  <li>
                    <span>(4)</span> - Crit DMG +10%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="green">Green Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_critres.png"
                  alt="Crit RES Set"
                />
              </td>
              <td>CRIT RES</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Crit RES +4%
                  </li>
                  <li>
                    <span>(4)</span> - Gain a 20% chance to block Debuffs for 2
                    turns at the start of the battle
                  </li>
                </ul>
              </td>
              <td>
                <strong className="green">Green Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_def.png"
                  alt="DEF Set"
                />
              </td>
              <td>DEF</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - DEF +250
                  </li>
                  <li>
                    <span>(4)</span> - DEF +20%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_hp.png"
                  alt="HP Set"
                />
              </td>
              <td>HP</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - HP +2000
                  </li>
                  <li>
                    <span>(4)</span> - HP +10%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="teal">Blue Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_pen.png"
                  alt="PEN Set"
                />
              </td>
              <td>PEN</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - PEN +5
                  </li>
                  <li>
                    <span>(4)</span> - DEF PEN +10%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="green">Green Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_speed.png"
                  alt="Speed Set"
                />
              </td>
              <td>SPEED</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Gain Speed +20% buff for 1 turn after
                    getting afflicted by a Abnormal Status
                  </li>
                  <li>
                    <span>(4)</span> - Gain Speed +4% buff for 1 turn at the
                    start of the battle
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/base_vampire.png"
                  alt="Vampire Set"
                />
              </td>
              <td>VAMPIRE</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Recover 10% HP after killing an enemy
                  </li>
                  <li>
                    <span>(4)</span> - Gain Lifesteal +20% when below 50% HP
                  </li>
                </ul>
              </td>
              <td>Arena Shop</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Advanced gear sets" />
        <Table striped bordered responsive className="table-sets bcm">
          <thead>
            <tr>
              <th>Icon</th>
              <th>Set Name</th>
              <th>Set Effects</th>
              <th>Drops from</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_atk.png"
                  alt="Atk Set"
                />
              </td>
              <td>ATK</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - ATK +7% and MATK +7%
                  </li>
                  <li>
                    <span>(4)</span> - Damage +15%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_crit.png"
                  alt="Crit Set"
                />
              </td>
              <td>CRIT Rate</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Crit Rate +5%
                  </li>
                  <li>
                    <span>(4)</span> - Crit Rate +10%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="teal">Blue Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_critdmg.png"
                  alt="Crit Dmg Set"
                />
              </td>
              <td>CRIT DMG</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Crit DMG +7%
                  </li>
                  <li>
                    <span>(4)</span> - Crit DMG +15%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="green">Green Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_def.png"
                  alt="DEF Set"
                />
              </td>
              <td>DEF</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - DEF +30%
                  </li>
                  <li>
                    <span>(4)</span> - DEF +40% and Endurance +8%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_hp.png"
                  alt="HP Set"
                />
              </td>
              <td>HP</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - HP +7%
                  </li>
                  <li>
                    <span>(4)</span> - Recover 10% HP at the start of each turn
                  </li>
                </ul>
              </td>
              <td>
                <strong className="teal">Blue Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_pen.png"
                  alt="PEN Set"
                />
              </td>
              <td>PEN</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - PEN +7
                  </li>
                  <li>
                    <span>(4)</span> - DEF PEN +15%
                  </li>
                </ul>
              </td>
              <td>
                <strong className="green">Green Dungeon</strong>
              </td>
            </tr>
            <tr>
              <td>
                <StaticImage
                  src="../../../images/blackclover/sets/gold_speed.png"
                  alt="Speed Set"
                />
              </td>
              <td>SPEED</td>
              <td>
                <ul>
                  <li>
                    <span>(2)</span> - Gain Speed +5% buff for 2 turn at the
                    start of the wave
                  </li>
                  <li>
                    <span>(4)</span> - Speed +5
                  </li>
                </ul>
              </td>
              <td>
                <strong className="red">Red Dungeon</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Best dungeon to farm" />
        <p>
          The Red Dungeon is the one you should focus on first as it not only is
          the easiest out of the three, but also it drops 3 gear sets that can
          be used on majority of characters - ATK, Speed and DEF.
        </p>
        <SectionHeader title="Best teams to farm gear" />
        <p>
          If you want to know what are the best teams to farm gear, check our
          other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best teams to farm gear"
            link="/black-clover/guides/best-teams-to-farm-gear"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_gear.png"
                alt="Best teams for farm gear"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesGearSets;

export const Head: React.FC = () => (
  <Seo
    title="Gear Sets | Black Clover M | Prydwen Institute"
    description="List of available gear sets in Black Clover Mobile."
    game="bcm"
  />
);
